import { Card, CardContent, CardHeader } from '@yonderlabs-packages/ui';

import ErrorIcon from '../../../public/icons/error.svg';

export function PageLevelErrorCard({ title, description }: { title: string; description: string }) {
  return (
    <div className='flex min-h-screen w-full items-center justify-center bg-error-foreground'>
      <Card className='max-w-screen-sm border-none'>
        <CardHeader>
          <img src={ErrorIcon} alt='error' className='w-max' />
        </CardHeader>
        <CardContent>
          <p className='mb-4 text-2xl font-semibold text-error'>{title}</p>
          <p className='mb-4'>{description}</p>
        </CardContent>
      </Card>
    </div>
  );
}
